import React from "react";
import { css } from "@emotion/react";
import { Trans } from "react-i18next";
import Card from "react-bootstrap/Card";
import { convertNumberToInt, formatCurrencySeparator, klookTranslatePrize } from "utils";
import { useAppSelector } from "redux/Store";
import classNames from "classnames";
import DOMPurify from "isomorphic-dompurify";
import EntryFee from "../../../common/EntryFee";
import GcashCoin from "assets/clients/gcash/gcash_coin.png";
import {
  isDana,
  isGoogly,
  isBountie,
  isKlook,
  isBubbaloo,
  isGcash,
  isGcashAliplus,
  isGo3,
} from "utils/applicationSlug";

const labelStyle = css`
  font-size: 14px;
  font-weight: 600;
`;

const cardBodyStyle = (theme: ITheme) => css`
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);

  &.div-group {
    color: ${theme.text.primary};
  }

  &.div-group:last-child {
    border-bottom: none;
  }

  .currency-symbol {
    width: 55px !important;
  }
`;

interface IPrizeCalculatorItemProps {
  prize: any;
  tournament: ITournament;
}

export default function PrizeCalculatorItem({ prize, tournament }: IPrizeCalculatorItemProps) {
  const { application, country } = useAppSelector((state: any) => state.common);

  const rankRange = prize.rank_from === prize.rank_to ? prize.rank_from : `${prize.rank_from} - ${prize.rank_to}`;
  const showRightIcon = !isBubbaloo;

  const getPrizeType = () => {
    if (tournament?.prize_calculation === "manual" || tournament?.prize_calculation === "dynamic") {
      return prize.prize_type || null;
    } else if (tournament?.prize_calculation === "automatic") {
      return tournament?.entry_fee_type || null;
    }
  };

  const prizeType = getPrizeType();

  const getCurrencyImage = () => {
    if (prizeType === "physical-prize") return "";
    return (
      <div className="px-1">
        <EntryFee entry_fee={""} payMethod={prizeType} onlyCurrencyIcon={true} />
      </div>
    );
  };

  const prizeAmount = () => {
    if (isBountie && prize?.prize_type === "cash") {
      return prize?.prize * 100;
    }

    if (isDana && (prize?.prize_type === "cash" || prize?.prize_type === "physical-prize")) {
      return formatCurrencySeparator(prize?.prize);
    }

    if (isKlook && prize?.klook_prize?.length > 0) {
      return klookTranslatePrize(prize, country);
    }

    const validPrizeTypes = ["physical-prize", "cash", "GoTokins", "GoPoints"];
    if (validPrizeTypes.includes(prize?.prize_type)) {
      return prize?.prize;
    }

    return convertNumberToInt(prize?.prize);
  };

  const getCashCurrency = () => {
    if (isBountie && prizeType === "cash") {
      return `&nbsp;${"Gems"}`;
    } else if (prizeType === "cash" && application?.payment_channel?.currency_symbol !== null) {
      return " " + application?.payment_channel?.currency_symbol;
    } else if (prizeType !== "gems" || prizeType !== "bountie-token") {
      return "";
    }
  };

  return (
    <Card.Body css={cardBodyStyle} className="div-group">
      <div className="row align-items-center py-4 prize-calculator-item">
        <div className="col-6 label-rank body-md" css={labelStyle}>
          <Trans i18nKey="rank">Rank</Trans>: <span>{rankRange}</span>
        </div>
        <div
          className={classNames("col text-end label-prize d-flex justify-content-end align-items-end", {
            "razer-gold-color": prizeType === "razer-gold",
            "razer-silver-color": prizeType === "razer-silver",
          })}
          css={labelStyle}
        >
          <b>
            {(isDana || isGo3) && (
              <>
                {getCurrencyImage()}
                {prizeAmount()}
              </>
            )}
          </b>

          {!isDana && !isGo3 && (
            <>
              {showRightIcon && prizeAmount()}
              {((application?.payment_channel?.custom_currency_icon || prize.prize_type === ("ticket" || "coin")) &&
                getCurrencyImage()) || (
                <span
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      `${getCashCurrency()}&nbsp;${
                        (isGoogly &&
                          (tournament?.prize_calculation === "dynamic" ? tournament?.entry_fee_currency : prizeType)) ||
                        ""
                      }`,
                    ),
                  }}
                />
              )}

              {!showRightIcon && prizeAmount()}
              {isGcash || isGcashAliplus ? (
                <img
                  src={GcashCoin}
                  alt=""
                  css={css`
                    height: 18px;
                    width: auto;
                    margin-left: 3px;
                    margin-bottom: 2px;
                  `}
                />
              ) : null}
            </>
          )}
        </div>
      </div>
    </Card.Body>
  );
}
